import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
	Elements,
	useStripe,
	useElements,
	PaymentElement,
	PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	TextField,
	Typography,
} from '@mui/material';
import axiosInstance from '../axiosInstance';
import logo from '../assets/logo.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = () => {
	const { account } = useParams();
	const [spinner, setSpinner] = useState(true);
	const [accountInfo, setAccountInfo] = useState(null);
	const [message, setMessage] = useState(null);
	const [inputError, setInputError] = useState(null);
	const [amount, setAmount] = useState('');
	const [address, setAddress] = useState('');
	const [clientSecret, setClientSecret] = useState('');

	useEffect(() => {
		const fetchAccountInfo = async () => {
			try {
				const response = await axiosInstance.get(
					`/checkout/account-info/${account}`
				);
				setSpinner(false);
				setAccountInfo(response?.data?.body ?? null);
			} catch (error) {
				setSpinner(false);
				setMessage(
					error?.response?.data?.message ??
						'Failed to fetch account info'
				);
			}
		};

		fetchAccountInfo();
	}, [account]);

	const handlePay = async () => {
		try {
			if (amount < 1) {
				return setInputError('Amount must be greater or equal 1');
			}

			if (accountInfo?.delivery && !address?.trim()) {
				return setInputError('Address is required');
			}

			setSpinner(true);
			const response = await axiosInstance.post(
				'/checkout/payment-intent',
				{ amount, address: address?.trim(), account }
			);

			setSpinner(false);
			if (!response?.data?.body?.clientSecret) {
				setMessage('Failed to create payment intent');
			} else {
				setClientSecret(response.data.body.clientSecret);
			}
		} catch (error) {
			setSpinner(false);
			const errorMessage =
				error?.response?.data?.message ??
				error?.message ??
				'Failed to create payment intent';
			if (error?.response?.status === 400) {
				setInputError(errorMessage);
			} else {
				setMessage(errorMessage);
			}
		}
	};

	return (
		<Container maxWidth="sm" sx={{ mt: 4 }}>
			<Box display="flex" justifyContent="center" mb={3} mt={3}>
				<img src={logo} alt="Logo" style={{ width: '150px' }} />
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '60vh',
				}}
			>
				{spinner ? (
					<CircularProgress size="90px" />
				) : message ? (
					<Typography variant="h6" color="white">
						{message}
					</Typography>
				) : (
					<Box bgcolor={'white'} padding={2} margin={2} textAlign="center">
						{!clientSecret ? (
							<>
								<Typography variant="h5">
									Pay {accountInfo?.account_holder}
								</Typography>
								<TextField
									id="amount-input"
									label="Enter Amount (£)"
									type="number"
									fullWidth
									margin="normal"
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
								/>
								{accountInfo?.delivery && (
									<TextField
										id="address-input"
										label="Enter Address"
										type="text"
										fullWidth
										margin="normal"
										value={address}
										onChange={(e) =>
											setAddress(e.target.value)
										}
									/>
								)}
								{inputError && (
									<Typography color="error">
										{inputError}
									</Typography>
								)}
								<Box sx={{ textAlign: 'center', mt: 2 }}>
									<Button
										variant="contained"
										color="primary"
										onClick={handlePay}
										fullWidth
									>
										Pay
									</Button>
								</Box>
							</>
						) : (
							<Elements
								stripe={stripePromise}
								options={{ clientSecret }}
							>
								<PaymentForm
									setMessage={setMessage}
									amount={amount}
									clientSecret={clientSecret}
								/>
							</Elements>
						)}
					</Box>
				)}
			</Box>
		</Container>
	);
};

const PaymentForm = ({ setMessage, amount, clientSecret }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [paymentRequest, setPaymentRequest] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (stripe && amount) {
			const pr = stripe.paymentRequest({
				country: 'GB',
				currency: 'gbp',
				total: {
					label: 'Total',
					amount: amount * 100,
				},
				disableWallets: ['link', 'browserCard'],
			});

			pr.canMakePayment().then((result) => {
				console.log('setPaymentRequest ', result);
				if (result) setPaymentRequest(pr);
			});

			pr.on('paymentmethod', async (ev) => {
				const { paymentIntent, error: confirmError } =
					await stripe.confirmCardPayment(
						clientSecret,
						{ payment_method: ev.paymentMethod.id },
						{ handleActions: false }
					);

				if (confirmError) {
					ev.complete('fail');
					setMessage(confirmError.message);
				} else {
					ev.complete('success');

					if (paymentIntent.status === 'requires_action') {
						const { error } = await stripe.confirmCardPayment(
							clientSecret
						);

						if (error) {
							setMessage(error.message);
						} else {
							setMessage('Payment done successfully!');
						}
					} else {
						setMessage('Payment done successfully!');
					}
				}
			});
		}
	}, [stripe, setMessage, amount, clientSecret]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: 'https://your-site.com/order/complete', // Your return URL
			},
			redirect: 'if_required',
		});

		setIsLoading(false);

		if (error) {
			setMessage(error.message);
		} else {
			setMessage('Payment done successfully!');
		}
	};

	return (
		<>
			<Typography variant="h5">Pay £{amount}</Typography>
			<Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
				<PaymentElement />
				<Button
					type="submit"
					variant="contained"
					color="primary"
					fullWidth
					disabled={isLoading}
					sx={{ mt: 3 }}
				>
					{isLoading ? 'Processing...' : 'Pay'}
				</Button>
				{paymentRequest && (
					<Box sx={{ mt: 2 }}>
						<PaymentRequestButtonElement
							options={{ paymentRequest: paymentRequest }}
						/>
					</Box>
				)}
			</Box>
		</>
	);
};

const Checkout = () => (
	<Elements stripe={stripePromise}>
		<CheckoutForm />
	</Elements>
);

export default Checkout;
