// axiosInstance.js
import axios from 'axios';
import {  } from 'react-router-dom';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
});

export const setupInterceptors = (logout) => {
    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            console.log("error.response.status : ", error?.response?.status)
            if (error?.response?.status === 401) {
                console.log('Call logout function')
                logout();
            }
            return Promise.reject(error);
        }
    );
};

export default axiosInstance;