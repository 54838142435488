import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import logo from '../assets/logo.png';
import axiosInstance from '../axiosInstance';
import {
	Container,
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableRow,
	CircularProgress,
	Box,
} from '@mui/material';

const countryList = [
	{ code: '+44', shortName: 'GB', name: 'United Kingdom', currency: 'GBP' },
	{ code: '+91', shortName: 'IN', name: 'India', currency: 'INR' },
	{ code: '+1', shortName: 'US', name: 'United States', currency: 'USD' },
];

function CreateLink() {
	const [email, setEmail] = useState('');
	const [countryCode, setCountryCode] = useState(countryList[0].code);
	const [phone, setPhone] = useState('');
	const [dob, setDob] = useState('');
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [accountHolder, setAccountHolder] = useState('');
	const [accountNumber, setAccountNumber] = useState('');
	const [routingNumber, setRoutingNumber] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [addDeliveryAddress, setAddDeliveryAddress] = useState(false);
	const [payLink, setPayLink] = useState(null);
	const [formErrors, setFormErrors] = useState({});
	const { authToken } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (authToken) {
			navigate('/');
		}
	}, [authToken, navigate]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const errors = {};

		if (
			!email ||
			!countryCode ||
			!phone ||
			!dob ||
			!address ||
			!city ||
			!postalCode ||
			!accountHolder ||
			!accountNumber ||
			!routingNumber
		) {
			errors.allFields = 'All fields are mandatory';
		}

		if (!/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i.test(email)) {
			errors.email = 'Invalid email address';
		}

		if (!/^\d{5,}$/.test(phone)) {
			errors.phone = 'Invalid phone number';
		}

		if (!/\b\d{4}-\d{2}-\d{2}\b/.test(dob)) {
			errors.dob = 'Invalid date of birth (YYYY-MM-DD)';
		}

		if (Object.keys(errors).length === 0) {
			try {
				setIsLoading(true);
				const res = await axiosInstance.post('/pay-link/', {
					email: email,
					country_code: countryCode,
					phone: phone,
					dob: dob,
					address: address,
					city: city,
					postal_code: postalCode,
					account_holder: accountHolder,
					account_number: accountNumber,
					routing_number: routingNumber,
					delivery:
						typeof addDeliveryAddress === 'boolean'
							? addDeliveryAddress
							: false,
				});
				setIsLoading(false);
				setPayLink(res?.data?.body?.payLink);
			} catch (error) {
				setIsLoading(false);
				errors.allFields =
					error?.response?.data?.message?.replace(/\n/g, ', ') ||
					'Something went wrong!';
				setFormErrors(errors);
			}
			return;
		} else {
			setFormErrors(errors);
		}
	};

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		alert('Link copied to clipboard!');
	};

	return (
		<Container maxWidth="sm">
			<Box display="flex" justifyContent="center" mb={3} mt={3}>
				<img src={logo} alt="Logo" style={{ width: '150px' }} />
			</Box>
			{payLink ? (
				<Box
					padding={2}
					margin={2}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '30vh',
					}}
				>
					<Box
						textAlign="center"
						bgcolor={'white'}
						padding={2}
						margin={2}
					>
						<Typography variant="inherit">
							Pay Link is created successfully
						</Typography>
						<Typography color="info.main" mt={2}>
							{payLink}
						</Typography>
						<Box textAlign="center" mt={2}>
							<Button
								variant="contained"
								onClick={() => copyToClipboard(payLink)}
							>
								Copy Link
							</Button>
						</Box>
					</Box>
				</Box>
			) : (
				<Box bgcolor={'white'} padding={2} margin={2}>
					<form onSubmit={handleSubmit}>
						<Typography variant="h4" gutterBottom>
							Create Pay Link
						</Typography>
						<Box mb={2}>
							<Typography variant="h6">
								Personal Details
							</Typography>
							<TextField
								label="Email"
								type="email"
								fullWidth
								margin="normal"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								error={!!formErrors.email}
								helperText={formErrors.email}
								required
							/>
							<TextField
								label="Phone Number"
								fullWidth
								margin="normal"
								InputProps={{
									startAdornment: (
										<TextField
											value={countryCode}
											onChange={(e) =>
												setCountryCode(e.target.value)
											}
											variant="standard"
											disabled
										/>
									),
								}}
								value={phone}
								type="number"
								onChange={(e) => setPhone(e.target.value)}
								placeholder="Phone Number"
								error={!!formErrors.phone}
								helperText={formErrors.phone}
								required
							/>
							<TextField
								label="Date of Birth"
								type="date"
								fullWidth
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}}
								value={dob}
								onChange={(e) => setDob(e.target.value)}
								error={!!formErrors.dob}
								helperText={formErrors.dob}
								required
							/>
						</Box>
						<Box mb={2}>
							<Typography variant="h6">
								Bank Details (Only GB bank details accepted)
							</Typography>
							<TextField
								label="Account Holder"
								fullWidth
								margin="normal"
								value={accountHolder}
								onChange={(e) =>
									setAccountHolder(e.target.value)
								}
								required
							/>
							<TextField
								label="Account Number"
								type="number"
								fullWidth
								margin="normal"
								value={accountNumber}
								onChange={(e) =>
									setAccountNumber(e.target.value)
								}
								required
							/>
							<TextField
								label="Sort Code"
								type="number"
								fullWidth
								margin="normal"
								value={routingNumber}
								onChange={(e) =>
									setRoutingNumber(e.target.value)
								}
								required
							/>
						</Box>
						<Box mb={2}>
							<Typography variant="h6">
								Address (Only GB addresses accepted)
							</Typography>
							<TextField
								label="Address Line 1"
								fullWidth
								margin="normal"
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								required
							/>
							<TextField
								label="City"
								fullWidth
								margin="normal"
								value={city}
								onChange={(e) => setCity(e.target.value)}
								required
							/>
							<TextField
								label="Postal Code"
								fullWidth
								margin="normal"
								value={postalCode}
								onChange={(e) => setPostalCode(e.target.value)}
								required
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={addDeliveryAddress}
										onChange={(e) =>
											setAddDeliveryAddress(
												e.target.checked
											)
										}
									/>
								}
								label="Add Delivery Address"
							/>
						</Box>
						{formErrors.allFields && (
							<Typography color="error">
								{formErrors.allFields}
							</Typography>
						)}
						<Box mt={2}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								fullWidth
								disabled={isLoading}
							>
								{isLoading ? (
									<CircularProgress size={24} />
								) : (
									'Create'
								)}
							</Button>
						</Box>
					</form>
				</Box>
			)}
		</Container>
	);
}

export default CreateLink;
