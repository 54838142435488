// src/AuthContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { setupInterceptors } from './axiosInstance';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('token'));
    const navigate = useNavigate();

    // useEffect(() => {
    //     setupInterceptors(logout);
    // }, []);

    const login = (token) => {
        setAuthToken(token);
        localStorage.setItem('token', token);
        navigate('/');
    };

    const logout = useCallback(() => {
        setAuthToken(null);
        localStorage.removeItem('token');
        navigate('/login');
    }, [navigate]);

    useEffect(() => {
        if (authToken) {
            localStorage.setItem('token', authToken);
        } else {
            localStorage.removeItem('token');
        }
        setupInterceptors(logout);
    }, [authToken, logout]);

    return (
        <AuthContext.Provider value={{ authToken, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
